import React from "react";

const Resume = ({ data }) => {
  if (data) {
    var skillmessage = data.skillmessage;
    var education = data.education.map(function (education) {
      //let educationDesc = education.description.split('*');
      return (
        <div style={{marginBottom:"50px"}} key={education.school}>
          <h3>{education.school}</h3>
          <p style={{marginBottom:"35px"}} className="info">
            {education.degree} <span>&bull;</span>
            <em className="date">{education.graduated}</em>
          </p>
          {/* {educationDesc.slice(1).map((description) => (
            <p style={{marginTop:"-15px", color:"black"}}><span style={{color:"red"}}>&bull; </span>{description}</p>
          ))} */}
          {education.school === "Dartmouth College, Hanover, NH, USA" ? 
            (<div>
              <p style={{marginTop:"-15px", color:"black"}}><span style={{color:"red"}}>&bull; </span><b>Major GPA:</b> 3.85/4.00</p>
              <p style={{marginTop:"-15px", color:"black"}}><span style={{color:"red"}}>&bull; </span><b>Full-ride scholarship</b> from Dartmouth College</p>
              <p style={{marginTop:"-15px", color:"black"}}><span style={{color:"red"}}>&bull; </span><b>Relevant Coursework:</b> Data Analysis, Linear Algebra, Differential Equations, Real Analysis, Abstract Algebra, Combinatorics, Probability Theory, Python Programming, Object-Oriented Programming with Java and C, Android Programming, Machine Learning and Statistics, Discrete Math, AI/Robotics, Database Systems, Software Design and Implementation, Operating Systems, Algorithms, Accelerated Computational Linguistics</p>
              <p style={{marginTop:"-15px", color:"black"}}><span style={{color:"red"}}>&bull; </span>Placed on the <b>College Honor List</b> for <b>outstanding academic performance</b> during the 2018-2019 & 2019-2020 years</p>
              <p style={{marginTop:"-15px", color:"black"}}><span style={{color:"red"}}>&bull; </span><b>Vice-President of Finance</b> for Dartmouth SIAM - <b>Society for Industrial and Applied Mathematics</b> (<a href="https://math.dartmouth.edu/~siamchapter/">https://math.dartmouth.edu/~siamchapter/</a>)</p>
            </div>) :
            (
              <div>
                <p style={{marginTop:"-15px", color:"black"}}><span style={{color:"red"}}>&bull; </span><b>Relevant Coursework (in progress):</b> Machine Learning, Advanced SWE I, Advanced SWE II, Scalable Computing, Research Methods and Innovation, Urban Computing, Next Generation Networks, Security & Privacy, Distributed Systems, Internet of Things, Artificial Intelligence, Adaptive Applications</p>
                <p style={{marginTop:"-15px", color:"black"}}><span style={{color:"red"}}>&bull; </span><b>Certified Google Cloud Developer (links to certificates):</b> <a href="https://www.credential.net/8477cce1-c1e1-43f8-b04a-dbb2d29767f2">Professional Google Cloud Network Engineer</a> and <a href="https://www.credential.net/5a869551-8226-4296-bda3-bc1273ab55e0">Associate Google Cloud Engineer</a></p>
              </div>
            )
          }
        </div>
      );
    });
    var work = data.work.map(function (work) {
      let wordDesc = work.description.split('*');
      return (
        <div style={{marginBottom:"70px"}} key={work.company}>
          <h3>{work.company}</h3>
          <p style={{marginBottom:"35px"}} className="info">
            {work.title} <span>&bull;</span>
            <em className="date">{work.years}</em>
          </p>
          {/* {wordDesc.slice(1).map((description) => (
            <p style={{marginTop:"-10px", color:"black"}}><span style={{color:"red"}}>&bull; </span>{description}</p>
          ))} */}
          {work.title === "Spy-themed 2-player VR & Mobile Game" && (
            <div>
                <p style={{marginTop:"-10px", color:"black"}}><span style={{color:"red"}}>&bull; </span>Having no prior experience in AR/VR development, our team came together hoping to create a new kind of VR game: a 2-player game using different media for each player. We wanted players to feel like Lucius Fox and Batman in “The Dark Knight,” with the former (mobile side) guiding the latter (VR side) through difficult scenarios (<a href="https://medium.com/dartmouth-cs98/making-a-multiplayer-vr-game-with-one-headset-e55a942ebc5d">medium article explaining the whole process</a>)</p>
                <p style={{marginTop:"-10px", color:"black"}}><span style={{color:"red"}}>&bull; </span>Work focused on gameplay design and a 3D maze minigame. Made in Unity (<b>C#</b>) for the Oculus Quest and Android (<a href="https://www.youtube.com/watch?v=K5Ua2laYNRA">link to demo</a>)</p>
            </div>
          )}
          {work.title === "SWE Intern for a Project Run by Microsoft and Sabancı" && (
            <div>
                {/* <p style={{marginTop:"-10px", color:"black"}}><span style={{color:"red"}}>&bull; </span></p> */}
                <p style={{marginTop:"-10px", color:"black"}}><span style={{color:"red"}}>&bull; </span>Became proficient in <b>Azure Stream Analytics</b> and <b>Azure App Service</b></p>
                <p style={{marginTop:"-10px", color:"black"}}><span style={{color:"red"}}>&bull; </span>Developed an <b>analytic model</b> that accurately suggests <b>set parameters</b> to reduce the energy consumption of raw mills and cement mills at Sabancı Holding's Cement Factories</p>
                <p style={{marginTop:"-10px", color:"black"}}><span style={{color:"red"}}>&bull; </span>Modified the <b>C# project</b> so it could transfer the <b>streaming sensor data from OPC servers to Microsoft Azure IoT Hub</b>. Did the <b>feature engineering</b> calculations on the streaming data for the analytic models and updated these models in Microsoft Azure. Trained each model, transferred them to separate Azure services, and finally implemented the analytic models on the streaming data</p>
                <p style={{marginTop:"-10px", color:"black"}}><span style={{color:"red"}}>&bull; </span>Designed a <b>service-oriented external tool</b>, namely a <b>Python program</b> that <b>automates fetching</b> hourly weather <b>data</b> for any given Sabancı factory. Deployed the <b>Python</b> program as a <b>web app</b> to <b>Microsoft Azure App Service.</b> <b>Created and deployed an easy-to-use API</b> for the company project</p>
                <p style={{marginTop:"-10px", color:"black"}}><span style={{color:"red"}}>&bull; </span>Excelled in <b>Python</b> and <b>C#</b> programming languages together with the <b>API creation/deployment processes</b> through the internship</p>
            </div>
          )}
          {work.title === "SWE Intern At Research and Development Department" && (
            <div>
                <p style={{marginTop:"-10px", color:"black"}}><span style={{color:"red"}}>&bull; </span>Improved the <b>software</b> used in the company to determine the power at which poultry barns’ ventilators should work based on the different temperature and humidity values of the place. <b>Designed and created a UI (React JS web app) to visualize the real-time changes in these values</b></p>
                <p style={{marginTop:"-10px", color:"black"}}><span style={{color:"red"}}>&bull; </span><b>Collected</b> and <b>uploaded</b> important <b>data</b> about the daily number of eggs collected from each floor’s carousel and the approximate number of deaths at each floor to the company’s <b>cloud and created a REST API using the collected data. Added a custom component to the React JS web app to track the real-time changes in the cloud as well</b></p>
                <p style={{marginTop:"-10px", color:"black"}}><span style={{color:"red"}}>&bull; </span><b>Created SQL database and APIs with the collected data.</b> The API that I built is now used for building/improving company's analytic model</p>
                <p style={{marginTop:"-10px", color:"black"}}><span style={{color:"red"}}>&bull; </span>Excelled in <b>Python, </b> <b>Java</b> and <b>JavaScript (HTML & CSS & React JS)</b> programming languages through the internship</p>
            </div>
          )}
          {work.title === "SWE Intern At Cyber Security And Network Technologies" && (
            <div>
                <p style={{marginTop:"-10px", color:"black"}}><span style={{color:"red"}}>&bull; </span>Improved the <b>company's firewall</b>, LOGO's main product used by many Turkish companies to secure their <b>networks</b></p>
                <p style={{marginTop:"-10px", color:"black"}}><span style={{color:"red"}}>&bull; </span>Worked on <b>URL categorization-classification/Web filtration</b> part of the company product. Created an <b>efficient software algorithm</b> in <b>Java</b> that quickly <b>determines</b> the specific <b>category of each URL domain</b> in question (e.g., education, government, social-media, adult, etc.) based on the website content, meta tags and various other features</p>
                <p style={{marginTop:"-10px", color:"black"}}><span style={{color:"red"}}>&bull; </span>Wrote an extensive <b>documentation of the application</b>, conducted various <b>tests</b> on it and integrated the <b>software application</b> to the company product. After that, I helped <b>improve the UI used for the company's firewall</b></p>
                <p style={{marginTop:"-10px", color:"black"}}><span style={{color:"red"}}>&bull; </span>During the second half of my internship, I applied <b>troubleshooting techniques</b> to <b>help customers integrate Logo's firewall into their networks</b></p>
                <p style={{marginTop:"-10px", color:"black"}}><span style={{color:"red"}}>&bull; </span>Excelled in <b>Java</b> and <b>JavaScript</b> programming languages together with <b>Network Systems & Protocols (e.g., DNS, SSH, IP/TCP, Firewalls, Routing)</b> during the internship</p>
            </div>
          )}
          {work.title === "Teaching Assistant Positions at Dartmouth College" && (
            <div>
                <p style={{marginTop:"-10px", color:"black"}}><span style={{color:"red"}}>&bull; </span><b>Cognitive Computing With Watson - CS.89.11 (03/2020 - 06/2020):</b> Course aiming to help students gain a broad understanding of the strengths & limitations of current question-answering technology via machine learning and AI solutions. Graded and provided feedbacks on 40+ assignments every week. Conducted weekly office hours (remote) to help students with the course material (<a href="https://www.cs.dartmouth.edu/~ccpalmer/teaching/cs89/Logistics/">course page for overview</a>)</p>
                <p style={{marginTop:"-10px", color:"black"}}><span style={{color:"red"}}>&bull; </span><b>Real Analysis - MATH.35 (01/2019 - 03/2019):</b> Graded and provided feedbacks on 30+ assignments every week. Topics include real numbers and cardinality of sets, sequences and series of real numbers, metric spaces, continuous functions, integration theory, sequences and series of functions, and polynomial approximation. A fundamental proof based MATH course mandatory to anyone aiming to major in Mathematics (<a href="https://math.dartmouth.edu/~m35w19/">course page for overview</a>)</p>
                <p style={{marginTop:"-10px", color:"black"}}><span style={{color:"red"}}>&bull; </span><b>Introduction to Python Programming - CS.01 (01/2017 - 03/2017):</b> Prepared materials for undergraduate students (e.g., Writing algorithms in Python with examples) and provided feedbacks on 30+ assignments every week. Conducted weekly office hours to help students with the course material. Conducted weekly lab sessions in which students solved practice questions based on the course material thought every week (<a href="https://dartmouth.smartcatalogiq.com/current/orc/Departments-Programs-Undergraduate/Computer-Science/COSC-Computer-Science-Undergraduate/COSC-1">course page for overview</a>)</p>
            </div>
          )}
          {/* {work.title === "Teaching Assistant for MATH.35 (Real Analysis)" && (
            <div>
                <p style={{marginTop:"-10px", color:"black"}}><span style={{color:"red"}}>&bull; </span>Graded and provided feedbacks on 30+ assignments every week. Topics include real numbers and <b>cardinality of sets</b>, <b>sequences</b> and <b>series of real numbers</b>, <b>metric spaces</b>, <b>continuous functions</b>, <b>integration theory</b>, <b>sequences and series of functions</b>, and <b>polynomial approximation</b></p>
                <p style={{marginTop:"-10px", color:"black"}}><span style={{color:"red"}}>&bull; </span>A fundamental proof based MATH course mandatory to anyone aiming to major in Mathematics (<a href="https://math.dartmouth.edu/~m35w19/">course page for overview</a>)</p>
            </div>
          )}
          {work.title === "Teaching Assistant for CS.01 (Python Programming)" && (
            <div>
                <p style={{marginTop:"-10px", color:"black"}}><span style={{color:"red"}}>&bull; </span>Prepared materials for undergraduate students (e.g., <b>Writing algorithms in Python with examples</b>) and provided feedbacks on 30+ assignments every week</p>
                <p style={{marginTop:"-10px", color:"black"}}><span style={{color:"red"}}>&bull; </span>Conducted weekly office hours to help students with the course material</p>
                <p style={{marginTop:"-10px", color:"black"}}><span style={{color:"red"}}>&bull; </span>Conducted weekly lab sessions in which students solved practice questions based on the course material thought every week (<a href="http://dartmouth.smartcatalogiq.com/current/orc/Departments-Programs-Undergraduate/Computer-Science/COSC-Computer-Science-Undergraduate/COSC-1">course page for overview</a>)</p>
            </div>
          )} */}
          
        </div>
      );
    });
    var skills = data.skills.map(function (skills) {
      var className = "bar-expand " + skills.name.toLowerCase();
      return (
        <li key={skills.name}>
          <span style={{ width: skills.level }} className={className}></span>
          <em>{skills.name}</em>
        </li>
      );
    });
  }

  return (
    <section id="resume">
      <div className="row education">
        <div className="three columns header-col">
          <h1>
            <span>Education</span>
          </h1>
        </div>

        <div className="nine columns main-col">
          <div className="row item">
            <div className="twelve columns">{education}</div>
          </div>
        </div>
      </div>

      <div className="row work">
        <div className="three columns header-col">
          <h1>
            <span>Work</span>
          </h1>
        </div>

        <div className="nine columns main-col">{work}</div>
      </div>

      <div className="row skill">
        <div className="three columns header-col">
          <h1>
            <span>Skills</span>
          </h1>
        </div>

        <div className="nine columns main-col">
          <p>{skillmessage}</p>

          <div className="bars">
            <ul className="skills">{skills}</ul>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Resume;
